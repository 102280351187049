import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';

export interface IAccommodation {
  id: number,
  ua_id?: number,
  uid: number,
  accommodation_slug: string,
  accommodation_value?: boolean,
  has_extra_notes?: boolean,
  extra_notes_value?:string
  group_id?: string,
  instit_group_id?:string;
}

export const handleRenderAssignedAccommsForDisplay = (lang:LangService, additional_accommodations: IAccommodation[]) => {
  if(!additional_accommodations.length){
    return "";
  }
  const activeAccommodations = additional_accommodations.filter(acc => !!acc.accommodation_value == true);
  const toDisplayList = [];
  for(let accommodation of activeAccommodations){
    if(!!accommodation.accommodation_value == true && accommodation.has_extra_notes){
      toDisplayList.push(`${lang.tra(accommodation.accommodation_slug)} "${accommodation.extra_notes_value}"`);
    }else if(!!accommodation.accommodation_value == true){
      toDisplayList.push(lang.tra(accommodation.accommodation_slug));
    }
  }
  return toDisplayList.join(', ');
}


@Component({
  selector: 'accommodations',
  templateUrl: './accommodations.component.html',
  styleUrls: ['./accommodations.component.scss']
})
export class AccommodationsComponent implements OnInit {

  @Output() updateAccommodations = new EventEmitter();
  @Input() isDisabled: boolean = false;
  @Input() forDisplay: boolean = true;
  @Input() test_session_id: number;
  @Input() uid: number;
  @Input() includeTitle: boolean = false;
  
  accommodationList = [];
  displayActiveAccommodations: string;
  is_loading: boolean = false;

  constructor(
    public lang: LangService,
    private whitelabel: WhitelabelService,
    private auth: AuthService,
    private routes: RoutesService,
  ) { }

  ngOnInit() {
    this.loadAccommodations();
  }
  
  loadAccommodations = async () => {
    if(this.test_session_id < 0 || !this.uid){
      return;
    }
    this.is_loading = true;
    this.accommodationList = await this.auth.apiFind(this.routes.TEST_ADMIN_ADDIONAL_ACCOMMS, {query:{test_session_id: this.test_session_id, uid: this.uid}});
    this.displayActiveAccommodations = handleRenderAssignedAccommsForDisplay(this.lang, this.accommodationList);
    this.is_loading = false;
  }

  handleUpdateAccommodation = () => {
    this.updateAccommodations.emit(this.accommodationList);
  }
}

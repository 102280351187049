<ng-container *ngIf="!forDisplay">
  <ng-container *ngFor="let acc of accommodationList">
    <div>
      <input [id]="acc.accommodation_slug" [disabled]="isDisabled" type="checkbox" [(ngModel)]="acc.accommodation_value" (change)="handleUpdateAccommodation()" />&nbsp;
      <label [for]="acc.accommodation_slug">
        <b><tra [slug]="acc.accommodation_slug"></tra></b>
      </label>&nbsp;
      <ng-container *ngIf="acc.has_extra_notes">
        <input type="text" [disabled]="!acc.accommodation_value || isDisabled" [(ngModel)]="acc.extra_notes_value" (change)="handleUpdateAccommodation()" />
      </ng-container>
    </div>
    <br>
  </ng-container>
</ng-container>
<ng-container *ngIf="forDisplay && displayActiveAccommodations">
  <div *ngIf="includeTitle">
    <tra slug="provided_accomm_title"></tra><br>
  </div>
  {{displayActiveAccommodations}}
</ng-container>
<div *ngIf="testState && !testState.isSubmitted" class="screen-warning">
    <tra slug="error_small_screen"></tra>
</div>

<div *ngIf="!testState.isSubmitted && !isPrintMode && !isShowingConfirmationReport" class="question-content-frame" [class.is-wide-load]="containsWideLoad()" [class.is-hi-contrast]="isHighContrast">

    <button *ngIf="!showDeactivatedMode && isShowOverlay" style="position:fixed; font-weight:bold; cursor:pointer; z-index:10; height:60px; right:5%; bottom:2%; background:#2A304B; margin:auto; border-top-left-radius: 1.3em; border-top-right-radius: 1.3em; padding:0 1em; color:white; font-size:1.2em;"
        (click)="disableOverlay()">
        {{lang.tra('draw_tool_exit')}}
    </button>

    <!-- <div *ngIf="isShowFormulaSheet" class="formula-container">
        <div class="formula-container__sheet">
            <div class="formula-container__sheet-header">
                <p>Formula Sheet</p>
                <button (click)="toggleFormula()"> <i class="fas fa-times"></i></button>
            </div>
        </div> -->

<!-- <div *ngIf="!testState.isSubmitted && !isPrintMode && !isShowingConfirmationReport"
     class="question-content-frame"
     [class.is-wide-load]="containsWideLoad()"
     [class.is-hi-contrast]="isHighContrast"
     [ngStyle]="{'font-size.em': zoomLevel}"
>
  <div *ngIf="!isHelpOverlay" class="question-content">
    <div class="question-content-constrainer is-fixedwidth" style="margin-bottom:2em;">
      <div class="question-loc-sm">
          <tra slug="title_section"></tra> {{testState.currentSectionIndex+1}},
          <tra slug="title_question"></tra> {{testState.currentQuestionIndex+1}}
      </div>
      <div style="position:relative" #topBar  [class.is-hidden]="isLineReaderActive">
          <progress
            class="progress is-info"
            style="margin-bottom:0px;"
            [value]="getCurrentProgressLoc(true)"
            max="100"
          >{{getCurrentProgressLoc()}}</progress>
          <div
            *ngFor="let section of currentTestDesign.sections; let isLast = last"
            class="section-progress-marker"
            [ngStyle]="{left: section.__meta.markLoc}"
            [class.is-hidden]="isLast"
          > </div>
      </div> -->
  <div *ngIf="isLineReaderActive">
    <div class="line-reader-container" id="quest-reader" [ngStyle]="{'zoom.%':currIEZoom}">
      <div cdkDrag class="line-reader" [cdkDragFreeDragPosition]="lineReaderPosition">
        <div class="line-reader-top"></div>
        <div class="line-reader-left"></div>
        <div class="line-reader-right"></div>
        <div class="line-reader-bottom"></div>
      </div>
    </div>
  </div>
  <div class="question-content" id="quest-cont">
        <div class="question-content-constrainer is-fixedwidth progress-bar-container">
            <div class="question-loc-sm">
                <tra slug="title_stage"></tra> {{testState.currentSectionIndex+1}},
                <tra slug="title_question"></tra> {{testState.currentQuestionIndex+1}}
            </div>
            <div style="position:relative" #topBar [class.is-hidden]="isLineReaderActive">
                <progress class="progress is-info" style="margin-bottom:0px;" [value]="getCurrentProgressLoc(true)" max="100">{{getCurrentProgressLoc()}}</progress>
                <div *ngFor="let section of currentTestDesign.sections; let isLast = last" class="section-progress-marker" [ngStyle]="{left: section.__meta.markLoc}" [class.is-hidden]="isLast"> </div>
            </div>
        </div>
        <div *ngIf="isFormulasToggledOn && isFormulasAvailable()">
          <formula-sheet [scale]="zoomLevel"></formula-sheet>
          <hr/>
        </div>
        <div class="helper-tools">
            <div class="question-content-constrainer" #questionDisplay >
                <div [ngStyle]="{'font-size.em': zoomLevel}">
                    <!-- Display Preamble: -->
                    <div *ngIf="isShowingSectionInfo && getCurrentSectionPreambleContent()" style="position: relative;">
                        <div style="font-size: 2em; color: #3298dc; position:absolute; left:-1em; top:0.1em;">
                            <i class="fa fa-info"></i>
                        </div>
                        <question-runner [currentQuestion]="getCurrentSectionPreambleContent()" [currentQuestionIndex]="0" [totalQuestions]="4"></question-runner>
                    </div>
                    <div class="next-button-container" *ngIf="isShowingSectionInfo">
                        <button (click)="hideSectionInfo()" class="button is-large is-info ">
                            <tra slug="btn_next_question_mpt"></tra>
                        </button>
                    </div>
                    <!-- Display Current Question: -->
                    <div [class.is-hidden]="isShowingSectionInfo">
                        <question-runner [currentQuestion]="getActiveQuestionContent()" [currentQuestionIndex]="0" [totalQuestions]="4" [questionState]="getActiveQuestionState()"></question-runner>
                    </div>
                    <div class="next-button-container" [class.is-hidden]="isShowingSectionInfo">
                        <button id="btn-submit-question" [disabled]="!isCurrentQuestionFilled()" *ngIf="!isOnLastQuestion()" (click)="gotoNextQuestion()" class="button is-large is-info">
                            <tra slug="btn_next_question_mpt"></tra>
                        </button>
                        <button id="btn-submit-question" [disabled]="!isCurrentQuestionFilled()" *ngIf="isOnLastQuestion()" [disabled]="isSubmitting" (click)="reviewAndSubmit()" class="button is-large is-info">
                            <tra slug="btn_review_submit"></tra>
                        </button>
                    </div>
                </div>

                <!-- Drawing Overlay -->
                <div *ngIf="isShowOverlay" class="question-content__overlay" [ngClass]="{'deactivated':showDeactivatedMode}" id="draw-overlay">
                    <!-- {{getActiveQuestionId()}} -->
                    <element-render-drawing
                            id="test-runner"
                            [parent]="getDrawingDisplayMode()"
                            [currId]="getActiveQuestionId()"
                            [activatedState]="!showDeactivatedMode"
                            [useEraser]="showEraser"
                            [useHighlighter]="showHighlight"
                            [useLine]="showLine"
                            [zoomLevel]="zoomLevel"
                            [isZoomIn]="isZoomIn"
                            [isFrameEnabled]="false"
                            [isToolbarEnabled]="false"
                    ></element-render-drawing>
                </div>
                <div
                        class="question-content__faux-overlay"
                        [class.animateDown]="isShowOverlay && !showDeactivatedMode"
                        [class.animateUp]="showDeactivatedMode"
                ></div>
            </div>
            <div *ngIf="isCalcAvailable()" style="z-index: 999;" >
                <widget-calculator [isSimple]="true" [zoom]="zoomLevel" *ngIf="isShowingCalc()"></widget-calculator>
            </div>
        </div>
    </div>
    <div *ngIf="isHelpOverlay" class="question-content question-content-help">
        <div class="question-content-constrainer is-fixedwidth" style="margin-bottom:2em;" >
            <div  [ngStyle]="{'font-size.em': zoomLevel}">
                <question-runner [currentQuestion]="getHelpScreenLayout()" [currentQuestionIndex]="0" [totalQuestions]="4"></question-runner>
            </div>
        </div>
    </div>
</div>



<div class="test-nav" [class.is-expanded]="isTestNavExpanded" *ngIf="!isLineReaderActive && !isPrintMode">
    <div>
        <div class="collapser">
            <button class="button" (click)="toggleTestNav()">
                <i class="fa fa-angle-double-left" aria-hidden="true"></i>
            </button>
        </div>
        <div class="support" *ngIf="false && isIssueReportingEnabled">
            <button (click)="reportIssue()">
                <img src="https://mathproficiencytest.ca/img/red-flag.png">
                <tra slug="btn_report_issue"></tra>
            </button>
        </div>

        <div *ngIf="isExitEnabled">
            <button class="button is-small is-fullwidth  is-danger has-icon" (click)="exit.emit()">
                <span class="icon"><i class="fa fa-times"></i></span>
                <span>Exit Preview</span>
            </button>
        </div>
        <div *ngIf="isShowQuestionLabel">
            <button class="button is-small is-fullwidth is-grey  has-icon" (click)="showCurrentQuestionLabel()">
                <span class="icon"><i class="fa fa-info"></i></span>
                <span>Identify Current Question</span>
            </button>
        </div>

        <div class="identity">
            <div class="identity-key"></div>
            <div class="identity-name">{{testTakerName}}</div>
            <div *ngIf="attemptKey" class="identity-key">
            <tra slug="txt_dummy_attempt_key"></tra>: {{attemptKey}}</div>
        </div>
        <div class="test-timing" *ngIf="!isNavFooterDisabled">
            <button (click)="checkTimeLeft()" *ngIf="isTimeEnabled" [attr.aria-label]="lang.tra('sr_timing_button')">
                <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/clock/1612200967437/clock.svg" aria-hidden="true">
            </button>
            <button (click)="openChat()" *ngIf="isChatEnabled" [attr.aria-label]="lang.tra('sr_chat_button')">
                <i class="far fa-comments" aria-hidden="true"></i>
            </button>
            <button *ngIf="chatService.newMessage && !isShowingChat" (click)="openChat()" [attr.aria-label]="lang.tra('sr_chat_notif_button')">
                <a><i class="fa fa-envelope is-danger" aria-hidden="true"></i></a>
            </button>
        </div>
        <div *ngIf="getLogo()" class="logo-container">
            <img [src]="getLogoSafe()">
        </div>

            <!-- <div class="test-title">
                <tra slug="brand_long"></tra>
            </div> -->

            <div class="test-section">
                <div *ngIf="currentTestDesign.sections.length > 1">
                    <tra [slug]="getSectionTitleSlug()"></tra>
                    {{(getCurrentSectionIndex() + 1)}}
                    <tra slug="of_enum"></tra>
                    {{currentTestDesign.sections.length}}
                </div>
                <button (click)="showSectionInfo()" 
                  [attr.aria-label]="lang.tra('ie_preamble')" 
                  class="button is-small" 
                  [class.is-info]="isShowingSectionInfo" 
                  *ngIf="getCurrentSectionPreambleContent()" 
                  style="margin-left: 2em;"><i class="fa fa-info"></i>
                </button>
                <div *ngIf="getCurrSectionTimeLimit()" style="font-size: 0.85em; font-weight: 400;">
                    <tra [slug]="getSectionTimeRemainingSlug()"></tra>: {{sectionTimeRemaining}}
                </div>
            </div>
        </div>
        <div class="test-questions">
            <button *ngFor="let question of getCurrentQuestions(); let qIndex = index;" class="question" tabindex="0" (click)="selectQuestion(qIndex)" [class.is-active]="!isShowingSectionInfo && getCurrentQuestionIndex() === qIndex">
                <span class="icon">
                    <i *ngIf="!isQuestionFilled(qIndex)" class="far fa-circle" [tooltip]="lang.tra('lbl_not_filled')" placement="left" aria-hidden="true"></i>
                    <i *ngIf="isQuestionFilled(qIndex)" class="fas fa-circle" [tooltip]="lang.tra('lbl_filled')" placement="left" aria-hidden="true"></i>
                </span>
                <span>
                    <tra slug="title_question"></tra> {{qIndex+1}}
                </span>
            </button>
            <button class="question" [disabled]="isSubmitting" (click)="reviewAndSubmit()">
                <tra slug="btn_review_submit"></tra>
            </button>
        </div>
        <div class="test-timing" *ngIf="!isNavFooterDisabled">
            <button (click)="checkTimeLeft()" *ngIf="isTimeEnabled" [attr.aria-label]="lang.tra('sr_timing_button')">
                <img src="https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/user_uploads/6388/authoring/clock/1612200967437/clock.svg" aria-hidden="true">
            </button>
            <button (click)="openChat()" *ngIf="isChatEnabled" [attr.aria-label]="lang.tra('sr_chat_button')">
                <i class="far fa-comments" aria-hidden="true"></i>
            </button>
            <button *ngIf="chatService.newMessage && !isShowingChat" (click)="openChat()" [attr.aria-label]="lang.tra('sr_chat_notif_button')">
                <a><i class="fa fa-envelope is-danger" aria-hidden="true"></i></a>
            </button>
        </div>
    </div>

    <div class="toolbar" *ngIf="!isPrintMode" [class.is-expanded]="isToolbarExpanded">
        <div class="tools-top">
            <button class="button collapser" (click)="toggleToolbar()">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            </button>
            <button class="toolbar-icon tool-help" [class.is-activated]="isHelpOverlay" (click)="toggleHelpScreen()" 
                    [tooltip]="lang.tra('btn_help')" placement="left"  *ngIf="isHelpEnabled" [attr.aria-label]="lang.tra('btn_help')">
                <!-- <img src="https://mathproficiencytest.ca/img/text-to-speech.png"> -->
                <i class="fa fa-question-circle" style="font-size: 2em;" aria-hidden="true"></i>
                <!-- Text to speech by Trevor Dsouza from the Noun Project -->
            </button>
            <button class="toolbar-icon" (click)="toggleTextToSpeech()" [class.is-active]="isTextToSpeechActive()" 
                    *ngIf="isText2SpeechEnabled" [tooltip]="lang.tra('btn_listen')" placement="left" [attr.aria-label]="lang.tra('btn_listen')">
                <img src="https://mathproficiencytest.ca/img/text-to-speech.png" aria-hidden="true">
                <!-- Text to speech by Trevor Dsouza from the Noun Project -->
            </button>
            <button class="toolbar-icon" (click)="zoomIn()" [tooltip]="lang.tra('btn_zoom_in')" placement="left" [attr.aria-label]="lang.tra('btn_zoom_in')">
                <img src="https://mathproficiencytest.ca/img/zoom-in.png" aria-hidden="true">
            </button>
            <button class="toolbar-icon" (click)="zoomOut()" [tooltip]="lang.tra('btn_zoom_out')" placement="left" [attr.aria-label]="lang.tra('btn_zoom_out')">
                <img src="https://mathproficiencytest.ca/img/zoom-out.png" aria-hidden="true">
            </button>
            <button class="toolbar-icon" (click)="toggleLineReader()" [class.is-active]="isLineReaderActive" 
                    [tooltip]="lang.tra('btn_line_reader')" placement="left" [attr.aria-label]="lang.tra('btn_line_reader')">
                <img src="https://www.mathproficiencytest.ca/img/line_reader.png" aria-hidden="true">
            </button>
            <button class="toolbar-icon" (click)="toggleHiContrast()" [class.is-active]="isHighContrast" [tooltip]="lang.tra('btn_hi_contrast')" 
                    placement="left" [attr.aria-label]="lang.tra('btn_hi_contrast')">
                <!-- hi contrast -->
                <img src="https://mathproficiencytest.ca/img/hi-contrast.png" aria-hidden="true">
            </button>
            <button *ngIf="!disableDrawingTools" class="toolbar-icon" (click)="setLine()" [class.is-active]="isShowOverlay && !showDeactivatedMode && showLine" [tooltip]="lang.tra('btn_toggle_editor')" placement="left">
                <i class="fas fa-edit" style="font-size: 2em; color:white;" aria-hidden="true"></i>
            </button>
            <button *ngIf="!disableDrawingTools" class="toolbar-icon" (click)="setHighlighter()" [class.is-active]="isShowOverlay && !showDeactivatedMode && showHighlight" [tooltip]="lang.tra('el_draw_highlighter')" placement="left">
                <i class="fas fa-highlighter" style="font-size: 2em; color:white;" aria-hidden="true"></i>
            </button>
            <button *ngIf="!disableDrawingTools" class="toolbar-icon" (click)="setEraser()" [class.is-active]="isShowOverlay && showEraser" [class.is-inactive]="!isShowOverlay || showDeactivatedMode" [tooltip]="lang.tra('btn_eraser')" placement="left" >
                <i class="fas fa-eraser" style="font-size: 2em; color:white;" aria-hidden="true"></i>
            </button>

        </div>
        <div class="tools-bottom">
            <button *ngIf="documentItems && documentItems.length"
            (click)="toggleDocuments()"
            class="toolbar-icon"
            [class.is-active]="isShowDocuments"
            [tooltip]="getDocumentTooltip()" placement="left"
            >
            <img src="https://mathproficiencytest.ca/img/reference-book.png">
        </button>
         <button *ngIf="isFormulasAvailable()" (click)="toggleFormulas()" class="toolbar-icon" [class.is-active]="isFormulasToggledOn" [tooltip]="lang.tra('btn_formulas')">
            <img src="https://mathproficiencytest.ca/img/reference-book.png">
        </button> 
        <button *ngIf="isCalcAvailable()" (click)="toggleCalc()" class="toolbar-icon" [class.is-active]="isShowingCalc()" [tooltip]="lang.tra('btn_calculator')" placement="left" [attr.aria-label]="lang.tra('btn_calculator')">
            <img src="https://mathproficiencytest.ca/img/calculator.png" aria-hidden="true">
        </button>
        <div class="debug-button is-embedded" (click)="triggerDebugModeAgg()"> </div>
    </div>
</div>

<div class="info-overlay" *ngIf="isShowingReport">
    <div class="info-box">
        <strong><tra slug="btn_report_issue"></tra></strong>
        <hr/>
        <textarea class="textarea" placeholder="Please describe the issue that you experienced in the box below" [formControl]="issueReportMessage" rows=6></textarea>
        <hr/>
        <div class="buttons">
            <button class="button" (click)="sendIssueReport()">
                Report Issue
            </button>
            <button class="button is-danger is-outlined" (click)="isShowingReport = false">
                Cancel
            </button>
        </div>
    </div>
</div>

<div class="info-overlay" *ngIf="isShowDocuments">
    <div class="info-box is-maximized documents-container">
        <div [ngStyle]="{'font-size.em': zoomLevel}">
            <div style="display:flex; flex-direction:row; justify-content: space-between;">
                <menu-bar style="flex-grow:1" [menuTabs]="documentViews" (change)="selectDocumentView($event)" [tabIdInit]="selectedDocumentId"></menu-bar>
                <a style="font-size:1.4em;" (click)="isShowDocuments = !isShowDocuments">&times;</a>
            </div>
            <div *ngIf="activeDocument">
                <question-runner [currentQuestion]="activeDocument" [currentQuestionIndex]="0" [totalQuestions]="4"></question-runner>
            </div>
        </div>
    <div class="info-overlay overlay-container" *ngIf="isShowingReport"></div>
    <div class="info-box">
      <strong><tra slug="btn_report_issue"></tra></strong>
      <hr/>
      <textarea class="textarea" placeholder="Please describe the issue that you experienced in the box below" [formControl]="issueReportMessage" rows=6></textarea>
      <hr/>
      <div class="buttons">
        <button class="button" (click)="sendIssueReport()">
          Report Issue
        </button>
        <button class="button is-danger is-outlined" (click)="isShowingReport = false">
          Cancel
        </button>
      </div>
    </div>
</div>

<!-- <div class="chat-overlay" *ngIf="isShowingChat">
    <div class="info-box">
        <div>
            <button class="button is-fullwidth" (click)="isShowingChat=false">
                Close
            </button>
        </div>
        <hr/>
        <div>
            <test-taker [uid]="auth.user().value.uid" [instit_group_id]="instit_group_id" [markingPoolId]="testSessionId" [chatService]="chatService"></test-taker>
        </div>
    </div>
</div> -->
<div class="save-overlay" [class.is-activated]="isSavingResponse">
    <div class="overlay-bg"></div>
    <div class="overlay-text">
        <span class="tag is-warning">Saving...</span>
    </div>
</div>

<div class="expander" style="top:0em; left:0em;" *ngIf="!isTestNavExpanded">
    <button class="button" (click)="toggleTestNav()">
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
    </button>
</div>

<div class="expander" style="top:0em; right:0em;" *ngIf="!isToolbarExpanded">
    <button class="button" (click)="toggleToolbar()">
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
    </button>
</div>

</div>

<div class="info-overlay overlay-container" *ngIf="isShowingTime">
    <div class="info-box">
        <div> <strong><tra slug="test_time_remaining"></tra></strong> </div>
        <div style="font-size:1.4em;">{{renderDur(regularTimeRemaining, lang)}}</div>
        <!-- <hr/> -->
        <!-- <div> <strong>Extra Time Remaining:</strong> </div> -->
        <!-- <div>02:00</div> -->
        <hr/>
        <div>
            <button class="button is-fullwidth" (click)="isShowingTime=false">
                OK
            </button>
        </div>
    </div>
</div>

<div *ngIf="isShowingConfirmationReport && testState.isSubmitted" class="report-container">
  <div class="report-modal">
    <div class="report-content">
      <tra-md slug="txt_instr_review_report"></tra-md>
        <tra-md slug="txt_finalize_submission"></tra-md>
      <table class="table is-bordered" style="margin-top:1em;">
        <tr>
          <th><tra slug="lbl_name"></tra></th>
          <td>{{testTakerName}}</td>
        </tr>
        <tr>
          <th><tra slug="lbl_assessment"></tra></th>
          <td><tra slug="lbl_MPT"></tra></td>
        </tr>
        <tr>
          <th><tra slug="txt_dummy_attempt_key"></tra> </th>
          <td>{{confirmReportData["attemptKey"]}}</td>
        </tr>
        <tr>
          <th><tra slug="lbl_invig_by"></tra> </th>
          <td>{{confirmReportData["invigilator"]}}<span> ({{confirmReportData["format"]}})</span></td>
        </tr>
        <tr>
          <th>Date</th>
          <td><tra-md [slug]="getDate()" [props]="{day: dayStarted, year: yearStarted}"></tra-md></td>
        </tr>
        <tr>
          <th><tra slug="lbl_started_at"></tra> </th>
          <td>{{getStartedAt()}}</td>
        </tr>
        <tr>
          <th><tra slug="lbl_time_spent"></tra> </th>
          <td>{{getTimeSpentSinceStart()}}</td>
        </tr>

      </table>

      <hr/>

      <table class="table is-bordered" style="    max-width: 17em; margin: auto;">
        <tr>
          <th style="width:8em;"><tra slug="ie_section"></tra></th>
          <th><tra slug="lbl_unfilled_responses"></tra></th>
          <th><tra slug="lbl_time_spent"></tra></th>
        </tr>
        <tr *ngFor="let section of currentTestDesign.sections; let index = index">
          <td><tra slug="ie_section"></tra> {{index+1}}</td>
          <td [textContent]="previousSessionMeta[index].numUnfilledResponses"></td>
          <td [textContent]="sectionTimeSpent[index] + ' minutes'"></td>
        </tr>
      </table>

      <hr/>
    </div>
    <div class="report-footer">
      <div> <button class="button is-info" (click)="submitTest()" style="width:100%;"> <tra slug="btn_continue"></tra> </button> </div>
      <!--<div> <button class="button is-info" (click)="confirmedSubmission()"> <tra slug="btn_tt_report_approve"></tra> </button> </div>
      <div> <button class="button" (click)="reportIssueThroughInvigilator()"> <tra slug="btn_tt_report_reject"></tra> </button> </div>-->
    </div>

  </div>
</div>

<!-- use no-display rather than ngIf since we need access to the ViewChild -->
<div class="chat-overlay overlay-container" [class.no-display]="!isShowingChat">
  <div class="info-box">
    <div class="chat-header">
      <div class="header-bookend"> <button class="button-close-chat" (click)="closeChat()"><i class="fa fa-times" aria-hidden="true"></i></button> </div>
      <div><tra slug="title_invig_messages"></tra></div>
      <div class="header-bookend"></div>
    </div>
    <div class="chat-body">
      <test-taker
        [uid]="auth.user().value?.uid"
        [instit_group_id]="instit_group_id"
        [markingPoolId]="testSessionId"
        [chatService]="chatService"
      ></test-taker>
    </div>
  </div>
</div>


<div *ngIf="isPrintMode" class="printed-test-form">
    <div>
        <strong> Test Form {{testFormId}}</strong>
        <!-- <div>The following sheets of paper must be shredded after digitization.</div> -->
    </div>
    <!-- section.isShuffleDisabled &&  !isShowQuestionLabel-->
    <div *ngFor="let section of currentTestDesign.sections; let index = index" [class.is-hidden]="">
        <div class="section-block">
            <h1 style="cursor:pointer;" (click)="toggleSectionPrint(index)">
                <tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}
                <span [ngSwitch]="!!sectionPrintDisplayRef.get(index)" style="margin-left:1em;">
                    <i *ngSwitchCase="false" class="fas fa-caret-right"></i>
                    <i *ngSwitchCase="true" class="fas fa-caret-down"></i>
                </span>
            </h1> 
            <ng-container *ngIf="sectionPrintDisplayRef.get(index)">
                <question-runner [currentQuestion]="getSectionPreambleContent(section)" [currentQuestionIndex]="0" [totalQuestions]="4"></question-runner>
            </ng-container>
        </div>
        <ng-container *ngIf="sectionPrintDisplayRef.get(index)">
            <div class="question-block" *ngFor="let question of section.questions; let qindex = index">
                <div style="border-top: 0.3em solid #666; padding:0.2em 0em;  font-weight:600; margin-top:1em;">
                    <tra [slug]="getSectionTitleSlug()"></tra> {{index+1}}, Question {{qindex+1}}
                    <span *ngIf="isShowQuestionLabel">[{{getQuestionLabel(question)}}]</span>
                </div>
                <question-runner [currentQuestion]="getQuestionDef(question)" [currentQuestionIndex]="0" [totalQuestions]="4"></question-runner>
            </div>
        </ng-container>
    </div>
</div>
<div class="debug-button" (click)="triggerDebugModeAgg()"> </div>
<div class="debug-box" *ngIf="isDebugMode">
    <div class="debug-content" cdkDrag>
        <div class="debug-current">
            <div class="debug-drag-bar" cdkDragHandle></div>
            <div><b>Current Form:</b> {{this.testFormId}}</div>
            <div><b>Current Label:</b> {{getQuestionLabel(getCurrentQuestionId())}}</div>
            <div><b>Current ID:</b> {{getCurrentQuestionId()}}</div>
            <div style="margin-top:1em"><button (click)="isDebugMode = false">x Close Debug Mode</button></div>
        </div>
        
        <div class="debug-last" *ngIf="lastResponseDebug">
            <div><b>Last Label:</b> {{lastResponseDebug.label}}</div>
            <div><b>Last Score:</b> {{lastResponseDebug.score}} / {{lastResponseDebug.weight}}</div>
            <div><b>Last Response:</b> {{lastResponseDebug.formatted_response}}</div>
        </div>
    </div>
</div>
import { API_ADDRESS_LOCAL } from "../../api/api-addr/l";
import { FooterType, IContextData } from "./common";

export let MPT_TEST_CTRL_GROUP_ID;


const footer = [
    [
        {"type": FooterType.ROUTE, "route":"/applicant/explore/about", "caption": "lm_title:applicant:about"},
        {"type": FooterType.ROUTE, "route":"/applicant/explore/before", "caption": "lm_title:applicant:before"},
        {"type": FooterType.ROUTE, "route":"/applicant/explore/register", "caption": "lm_title:applicant:register"},
        {"type": FooterType.ROUTE, "route":"/applicant/explore/access_accomm", "caption": "lm_title:applicant:access_accomm"}
    ],
    [
        {"type": FooterType.ROUTE, "route":"/applicant/explore/testday", "caption": "lm_title:applicant:testday"},
        {"type": FooterType.ROUTE, "route":"/applicant/explore/after_test", "caption": "lm_title:applicant:after_test"},
        {"type": FooterType.ROUTE, "route":"/applicant/explore/land_knowledge", "caption": "lm_title:applicant:land_knowledge"},
    ],
    [
        {"type": FooterType.ROUTE, "route":"/privacy", "caption": "title_privacy"},
        {"type": FooterType.ROUTE, "route":"/legal", "caption": "title_legal_mpt"},
        {"type": FooterType.ROUTE, "route":"/contact", "caption": "title_contactus"},
        {"type": FooterType.IMAGE_LINKS, "imgLinks": [
          { "urlTranslationSlug": "mpt_x_link", "src": "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515512/authoring/social_x/1724953569770/social_x.png"},
          { "urlTranslationSlug": "mpt_linkedin_link", "src": "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515512/authoring/social_li/1724956566095/social_li.png"},
          { "urlTranslationSlug": "mpt_facebook_link", "src": "https://d3azfb2wuqle4e.cloudfront.net/user_uploads/515512/authoring/social_fb/1724952437091/social_fb.png"},
        ]},
    ]
  ]

const apiAddress = (hostname:string) => {
    switch(hostname){
      case 'xqc1.mathproficiencytest.ca': return 'https://xqc1-api.mathproficiencytest.ca';
      // case 'qc1.mathproficiencytest.ca': return 'https://api-eassessment.vretta.com';
      // case 'qc1.mathproficiencytest.ca': return 'https://api.mathproficiencytest.ca';
      case 'qc1.mathproficiencytest.ca': return 'https://qc1-api.mathproficiencytest.ca';
      // case 'localhost':
      case 'd2f95x08189c1c.cloudfront.net': return 'https://qc3-api.mathproficiencytest.ca';
      case 'qc3.mathproficiencytest.ca': return 'https://qc3-api.mathproficiencytest.ca';
      case 'eqao-mpt-qc.vretta.com': return 'https://eqao-mpt-qc-api.vretta.com';
      // case 'qc3.mathproficiencytest.ca': return 'https://api.mathproficiencytest.ca';
      // case 'localhost':  return 'https://api-eassessment.vretta.com';
      case 'localhost': 
        // return 'https://qc3-api.mathproficiencytest.ca';
        // return 'https://api.mathproficiencytest.ca';
        // return 'https://eqao-mpt-prelive-api.vretta.com';
        return API_ADDRESS_LOCAL;
      // case 'qc3.mathproficiencytest.ca':
      // case 'localhost':
      case 'eqao-mpt-prelive.vretta.com': return 'https://eqao-mpt-prelive-api.vretta.com';
      case 'eqao-mpt-uat.vretta.com': return 'https://eqao-mpt-uat-api.vretta.com';
      case 'testcompetencesmaths.ca':
      case 'mathproficiencytest.ca':  
      case 'www.mathproficiencytest.ca':
      case 'eqao-mpt.vretta.com':
      case 'www.testcompetencesmaths.ca':
      default: return 'https://eqao-mpt-api.vretta.com';
    }
  }

  const setMPTctrlID = (hostname:string) => {
    console.log(hostname)
    switch(hostname){
      case 'd2f95x08189c1c.cloudfront.net': 
      case 'qc3.mathproficiencytest.ca':
      case 'localhost': 
      case 'eqao-mpt-qc.vretta.com':
      case 'www.eqao-mpt-qc.vretta.com':MPT_TEST_CTRL_GROUP_ID = 27; break;
      case 'eqao-mpt-prelive.vretta.com':
      case 'www.eqao-mpt-prelive.vretta.com':
      case 'eqao-mpt.vretta.com':
      case 'www.testcompetencesmaths.ca': 
      case 'www.mathproficiencytest.ca':
      case 'mathproficiencytest.ca': 
      default : MPT_TEST_CTRL_GROUP_ID = 24;
    }
  }

export const MPT:IContextData = {
    id: 'MPT',
    homepageRoute: '/mpt-launch',
    brandName: 'brand_short',
    defaultTimezone: 'America/Toronto',
    logo: 'eqao_logo',
    footer,
    setMPTctrlID,
    apiAddress,
    siteFlags: {
      BREADCRUMB_MENU: true,
      BOOKING_POLICY_STATS: true,
    },
    siteText: {
      copyright: 'linked_title_copyright',
      supportEmail: 'support@mathproficiencytest.zendesk.com',
      supportPhone: '1 888 327-7377',
      my_inst: 'ta_my_faculty_lbl',
      my_inst_admin: 'lbl_inst_mngr_you',
      txt_support_popup: 'txt_support_popup_mpt',
    },
    hosts: [
        'qc3.mathproficiencytest.ca',
        'localhost:4200',
        'mathproficiencytest.ca',
        'd2f95x08189c1c.cloudfront.net',
        'testcompetencesmaths.ca',
        'www.mathproficiencytest.ca',
        'www.testcompetencesmaths.ca',
        'qc1.mathproficiencytest.ca',
    ]
}
